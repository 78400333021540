<template>
  <div>
    <BRow
      v-if="errorsState.type === 'validation'"
      class="py-2 m-1"
    >
      <div v-if="errorsState.data[0].message !== 'different warehouse'">
        <BCol
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          />
        </BCol>
        <BCol
          cols="12"
          class="d-flex flex-column pl-2 mb-2"
        >
          <div class="d-flex align-items-center mb-50">
            <BImg
              src="https://storage.googleapis.com/komerce/assets/svg/Ellipsered.svg"
              class="mr-50"
            />
            <h5 class="text-black mb-0">
              Beberapa  data order kurang tepat
            </h5>
          </div>
          <div class="d-flex flex-column text-black ml-1">
            <span>
              Identifikasi teratas :
            </span>
            <span v-if="errorsState.data[0].message === 'product out of stock'">
              Produk yang kamu pilih pada baris ke {{ errorsState.data[0].row }} stoknya habis
            </span>
            <span v-else>
              Data “baris ke {{ errorsState.data[0] }}” tidak sesuai format
            </span>
          </div>
        </BCol>

      </div>
      <div v-else>
        <BCol
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="100"
          />

        </BCol>
        <BCol
          cols="12"
          class="d-flex flex-column mb-2 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Beberapa Order Belum Tepat
            </strong>
          </span>
          <div class="d-flex flex-column text-black text-center mt-1">
            <span>
              Identifikasi teratas :
            </span>
            <span>
              Kamu tidak bisa menggunakan gudang yang berbeda dalam satu orderan
            </span>
          </div>
        </BCol>
      </div>
      <BCol
        cols="12"
        class="d-flex justify-content-center"
      >
        <BButton
          variant="primary"
          class="btn-fix"
          @click="hide"
        >
          <h5 class="mb-0 text-white">
            <strong>
              Perbaiki
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>

    <BRow
      v-if="errorsState.type === 'cod'"
      class="py-2 m-1"
    >
      <BCol
        cols="12"
        class="d-flex justify-content-center mb-2"
      >
        <BImg
          src="https://storage.googleapis.com/komerce/assets/icons/non-cod.svg"
        />
      </BCol>
      <BCol
        cols="12"
        class="d-flex flex-column pl-2 mb-2"
      >
        <div class="d-flex align-items-center mb-50">
          <BImg
            src="https://storage.googleapis.com/komerce/assets/svg/Ellipsered.svg"
            class="mr-50"
          />
          <h5 class="text-black mb-0">
            Beberapa  data order kurang tepat
          </h5>
        </div>
        <div class="d-flex flex-column text-black ml-1">
          <span>
            Identifikasi teratas :
          </span>
          <span>
            Data “baris ke {{ errorsState.data[0] }}” diluar jangkauan wilayah COD atau Kode Pos belum tepat
          </span>
        </div>
      </BCol>
      <BCol
        cols="12"
        class="d-flex justify-content-center"
      >
        <BButton
          variant="primary"
          class="btn-fix"
          @click="hide"
        >
          <h5 class="mb-0 text-white">
            <strong>
              Perbaiki
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>

    <BRow
      v-if="errorsState.type === 'shipment cod'"
      class="py-2 m-1"
    >
      <BCol
        cols="12"
        class="d-flex justify-content-center mb-2"
      >
        <BImg
          src="https://storage.googleapis.com/komerce/assets/icons/non-shipping.svg"
        />
      </BCol>
      <BCol
        cols="12"
        class="d-flex flex-column pl-2 mb-2"
      >
        <div class="d-flex align-items-center mb-50">
          <BImg
            src="https://storage.googleapis.com/komerce/assets/svg/Ellipsered.svg"
            class="mr-50"
          />
          <h5 class="text-black mb-0">
            Beberapa  data order kurang tepat
          </h5>
        </div>
        <div class="d-flex flex-column text-black ml-1">
          <span>
            Identifikasi teratas :
          </span>
          <span>
            Data “baris ke {{ errorsState.data[0] }}” <strong>alamat tujuannya</strong> diluar jangkauan ekspedisi yang dipilih, <strong>mohon pilih ekspedisi lainnya</strong>.
          </span>
        </div>
      </BCol>
      <BCol
        cols="12"
        class="d-flex justify-content-center"
      >
        <BButton
          variant="primary"
          class="btn-fix"
          @click="hide"
        >
          <h5 class="mb-0 text-white">
            <strong>
              Perbaiki
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>

    <BRow
      v-if="errorsState.type === 'balance'"
      class="py-2 m-1"
    >
      <BCol
        cols="12"
        class="d-flex justify-content-center mb-2"
      >
        <BImg
          src="https://storage.googleapis.com/komerce/assets/icons/non-shipping.svg"
        />
      </BCol>
      <BCol
        cols="12"
        class="d-flex flex-column pl-2 mb-2"
      >
        <div class="d-flex align-items-center mb-50">
          <BImg
            src="https://storage.googleapis.com/komerce/assets/svg/Ellipsered.svg"
            class="mr-50"
          />
          <h5 class="text-black mb-0">
            Upps.. Saldo Belum Mencukupi
          </h5>
        </div>
        <div class="d-flex">
          <span class="text-black">
            Kamu harus mempunyai saldo lebih dari **Rp. {{ formatCurrency(errorsState.data) }}** ya, sesuai dengan nilai total ongkir atas orderan yang diinput.
          </span>
        </div>
      </BCol>
      <BCol
        cols="12"
        class="d-flex justify-content-center"
      >
        <BButton
          variant="primary"
          class="btn-fix"
          @click="hide"
        >
          <h5 class="mb-0 text-white">
            <strong>
              Perbaiki
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>
  </div>
</template>

<script>
const onlyNumberRegex = /\B(?=(\d{3})+(?!\d))/g
export default {
  props: ['errorsState'],
  methods: {
    hide() {
      this.$emit('hide')
    },
    formatCurrency: value => `${value}`.replace(/\D/g, '').replace(onlyNumberRegex, '.'),
  },
}
</script>

<style scoped>
    .btn-fix {
        width: 200px;
        height: 50px;
        border-radius: 12px;
        letter-spacing: 1px;
    }
</style>
